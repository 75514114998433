import React from 'react'
import styled from 'styled-components'

import Header from '../components/Header/Header'
import MaxWidthWrapper from '../components/MaxWidthWrapper/MaxWidthWrapper'

const StandardLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const StandardLayoutHeaderWrapper = styled.header`
  position: sticky;
  top: -1px;
  z-index: 3;
  background: var(--color-background);
  transition: background 350ms ease 0s;
`
const StandardLayoutChildrenWrapper = styled.div`
  position: relative;
  z-index: 1;
  flex: 1 1 0%;
  transition: background 350ms ease 0s;
  max-width: 100vw;
  overflow: hidden;
`

const DefaultLayout: React.FC = ({ children }) => (
  <StandardLayoutWrapper>
    <StandardLayoutHeaderWrapper>
      <Header />
    </StandardLayoutHeaderWrapper>
    <StandardLayoutChildrenWrapper>{children}</StandardLayoutChildrenWrapper>
  </StandardLayoutWrapper>
)

export default DefaultLayout
