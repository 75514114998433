import React from 'react';
import styled from 'styled-components';

const StyledMaxWidthWrapper = styled.div`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
`;

const MaxWidthWrapper: React.FC = ({ children }) => (
  <StyledMaxWidthWrapper>{children}</StyledMaxWidthWrapper>
);

export default MaxWidthWrapper;
